:root {
  --doc-height: 100%;
 }

body, textarea, * {
  font-family: 'Inter', sans-serif;
  margin: 0;
}

body {
  padding: 0;
  margin-top: env(safe-area-inset-top);
  height: calc(100% - env(safe-area-inset-top));
  position: fixed;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img[data-test="powered-by-image"] {
  opacity: 0;
}

h1 {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

a {
  text-decoration: none;
  color: #2486B0;
}

/* react-modal styles */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  z-index: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  transform: translate(-50%, 150%);
  transition: transform 800ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translate(-50%, -50%);
}

.ReactModal__Content--before-close {
  transform: translate(-50%, 150%);
}

/* react-toastify */

.Toastify__toast-container--bottom-center {
  left: 16px;
  width: calc(100vw - 32px);
  bottom: 96px;
}

@media screen and (min-width: 767px) {
  .Toastify__toast-container--bottom-center {
    left: calc(25vw - 8px);
    width: calc(50vw - 32px);
    bottom: 96px;
  }
}